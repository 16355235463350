import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import { useQueryParam, StringParam } from 'use-query-params'
import { navigate } from 'gatsby'
import { useSiteState, useLang } from '~context/siteContext'

const SearchBar = ({ className, placeholder }) => {

	const [term, setTerm] = useQueryParam('q', StringParam)
	const [siteState, setSiteState] = useSiteState()
	const [ lang ] = useLang()

	const handleSubmit = e => {
		e.preventDefault()
		let term = e.target[0].value
		navigate(`/search?q=${term}`)
		setTimeout(() => {
			setSiteState(prevState => ({
				...prevState,
				searchOpen: false
			}))
		}, 100)
	}

	const inputRef = useRef()

	useEffect(() => {
		if (siteState.searchOpen){
			inputRef.current.value = null
			inputRef.current.focus()
		}
	}, [siteState.searchOpen])

	useEffect(() => {
		if(term && inputRef.current){
			inputRef.current.value = term
		}
	}, [term])

	return (
		<Wrap className={className} onSubmit={handleSubmit}>
			<Input type="text" className="h1" ref={inputRef} autoFocus placeholder={placeholder[lang]}/>
			{/* <Button type="submit" onClick={() => null}>Search</Button> */}
		</Wrap>
	)
}

const Wrap = styled.form`
	
`
const Input = styled.input`
	background-color: transparent;
	border: none;
	color: inherit;
	:focus {
		border: none;
    background-color: transparent;
    resize: none;
    outline: none;
	}
	::placeholder{
		color: inherit;
	}
`
const Button = styled.button`
	
`
SearchBar.propTypes = {
	className: PropTypes.string,
	placeholder: PropTypes.object,
}

export default SearchBar