const resolveLink = (doc) => {
	if(!doc) return null
	const type = doc._type
	const slug = doc.slug?.current

	switch (type) {
	case 'resource':
		return `/resources/${slug}`
	case 'project':
		return `/projects/${slug}`
	case 'page':
		return `/${slug}`
	case 'homePage':
		return '/'
	default:
		return `/${slug}`
	}

}

export default resolveLink
