import { graphql, useStaticQuery } from 'gatsby'

const useSiteSettings = () => {

	const siteSettings = useStaticQuery(graphql`
    query {
      sanitySiteSettings {
        siteSocialImage: socialImage {
          asset {
            _id
          }
        }
        siteMetaDescription: metaDescription
        siteTitle
				footerTitle {
					...multiString
				}
				ausPhone
				tlPhone
				email
				supporters {
					image {
						...imageWithAlt
					}
					link {
						...link
					}
				}
				joinButton {
						...button
				}
				socialLinks {
					...button
				}
				supportText {
					...multiString
				}
				navigationLinks{
					_key
					text {
						english
						tetum
					}
					link{
						...link
					}
				}
				searchButtonText{
					...multiString
				}
				searchPlaceholderText{
					...multiString
				}
				menuButtonText{
					...multiString
				}
				closeButtonText{
					...multiString
				}
				sortBy{
					...multiString
				}
				newestOldest{
					...multiString
				}
				oldestNewest{
					...multiString
				}
				alphabeticalAZ{
					...multiString
				}
				alphabeticalZA{
					...multiString
				}
				relevanceLowHigh{
					...multiString
				}
				relevanceHighLow{
					...multiString
				}
				filterBy{
					...multiString
				}
				backToIndex{
					...multiString
				}
				submit{
					...multiString
				}
				emailText{
					...multiString
				}
      }
    }
    `)

	return siteSettings?.sanitySiteSettings
}

export default useSiteSettings
