import React from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import Section from '~components/Section'
import useSiteSettings from '~utils/useSiteSettings'
import MailchimpForm from '~components/MailchimpForm'
import DataViewer from '~utils/DataViewer'
import Multi from '~components/Multi'
import SanityLink from '~components/SanityLink'
import { AusAid, AsiaFound, RDTL, Arrow } from '~components/Svg'
import { mobile } from '~styles/global'

const Footer = ({ className }) => {
	const {	footerTitle, ausPhone, tlPhone, email, supporters, joinButton, socialLinks, supportText } = useSiteSettings()

	return(
		<Wrap className={className}>
			<Section>
				<Left>
					<Heading className='sm'><Multi>{footerTitle}</Multi></Heading>
					{ausPhone && <Phone className='sm' href={`tel:${ausPhone}`}><Area>Aus</Area> {ausPhone}</Phone>}
					{tlPhone && <Phone className='sm' href={`tel:${tlPhone}`}><Area>TL</Area> {tlPhone}</Phone>}
					<Email 
						className='sm'
						href=""
						link={{
							url: `mailto:${email}`
						}}
					>{email}</Email>
				</Left>
				<Right>
					<SignUpForm successMessage='Success'/>
					<SocialLinks className='caption'>
						{joinButton?.text && <SocialTitle link={joinButton.link}><Multi>{joinButton?.text}</Multi></SocialTitle>}
						{socialLinks?.map(socialLink => (
							<SocialLink key={socialLink.text.english} link={socialLink.link}>
								<Multi>{socialLink.text}</Multi>
							</SocialLink>
						))}
					</SocialLinks>
				</Right>
			</Section>
			<Bottom>
				<Logos>
					<AustraliaAid />
					<TheAsiaFoundation />
					<RDTLLogo />
				</Logos>
				<Credits className='caption'>
					<Multi>{supportText}</Multi>
					<Afom link={{linkType: 'external', url: 'https://afom.com.au/', blank: true}}>Site by AFOM<ArrowMobile /></Afom>
				</Credits>
			</Bottom>
			{/* <DataViewer data={{footerTitle, ausPhone, tlPhone, email, supporters, joinButton, socialLinks, supportText}} name="data"/> */}
		</Wrap>
	)
}

const Wrap = styled.footer`
	background-color: var(--beige);
	padding-top: 45px;
`
const Left = styled.div`
	grid-column: span 6;
	margin-top: 20px;
	${mobile}{
		grid-column: span 12;
		margin-bottom: 48px;
	}
`
const Heading = styled.div`
	margin-bottom: 86px;
	${mobile}{
		margin-bottom: 46px;
	}
`
const Area = styled.div`
	min-width: 63px;
	display: inline-block;
	${mobile}{
		min-width: 40px;
	}
`
const Phone = styled.a`
	display: block;
	transition: color 0.25s;
	:hover {
		color: var(--purple);
	}
	${mobile}{
		margin-bottom: 12px;
	}
`
const Email = styled(SanityLink)`
	display: block;
	transition: color 0.25s;
	:hover {
		color: var(--purple);
	}
`
const SignUpForm = styled(MailchimpForm)`
	margin-bottom: 76px;
`
const SocialLinks = styled.div`
	
`
const SocialTitle = styled(SanityLink)`
	display: block;
	margin-bottom: 30px;
	${mobile}{
		margin-bottom: 15px;
	}
`
const SocialLink = styled(SanityLink)`
	display: block;
	transition: color 0.25s;
	:hover {
		color: var(--purple);
	}
	${mobile}{
		margin-bottom: 10px;
	}
`
const Bottom = styled(Section)`
	margin-top: 240px;
	margin-bottom: 34px;
	${mobile}{
		margin-top: 80px;
	}
`
const Credits = styled.div`
	grid-column: span 6;
	display: flex;
	justify-content: space-between;
	align-items: end;
	${mobile}{
		grid-column: span 12;
		flex-direction: column-reverse;
    align-items: start;
		margin-bottom: 20px;
	}
`
const Afom = styled(SanityLink)`
transition: color 0.25s;
:hover {
	color: var(--purple);
}
${mobile}{
	margin-bottom: 10px;
}
	
`
const Right = styled.div`
	grid-column: span 6;
	${mobile}{
		grid-column: span 12;
	}
`
const Logos = styled.div`
	grid-column: span 6;
	${mobile}{
		grid-column: span 12;
		display: flex;
		justify-content: space-between;
		align-content: center;
		margin-bottom: 48px;
	}
`
const AustraliaAid = styled(AusAid)`
	width: 128px;
	margin-right: 62px;
	${mobile}{
		margin-right: 0;
		max-width: 89px;
	}
`
const TheAsiaFoundation = styled(AsiaFound)`
	width: 150px;
	margin-right: 61px;
	${mobile}{
		margin-right: 0;
		max-width: 104px;
	}
`
const RDTLLogo = styled(RDTL)`
	width: 67px;
	${mobile}{
		max-width: 50px;
	}

`
const ArrowMobile = styled(Arrow)`
	display: none;
	${mobile}{
		display: inline-block;
		margin-left: 6px;
		width: 9px;
	}
`
Footer.propTypes = {
	className: PropTypes.string,
}

export default Footer
