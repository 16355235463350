import React from 'react'
import PropTypes from 'prop-types'
import { useLang } from '../context/siteContext'
import RichText from '~components/RichText'

const MultiString = ({ children }) => {
	const [ lang ] = useLang()
	return (
		<>
			{children[lang]}
		</>
	)
}

const MultiText = ({ children, textSyles }) => {
	const [ lang ] = useLang()
	return (
		<RichText textSyles={textSyles} content={children[lang]}/>
	)
}

const Multi = ({ children, textSyles }) => {
	if(!children?.english) return null
	return typeof children?.english === 'string' ? 
		<MultiString>{children}</MultiString> 
		: <MultiText textSyles={textSyles}>{children}</MultiText>
}

Multi.propTypes = {
	children: PropTypes.object,
	textSyles: PropTypes.bool,
}

MultiString.propTypes = {
	children: PropTypes.object,
}

MultiText.propTypes = {
	children: PropTypes.object,
	textSyles: PropTypes.bool,
}

export default Multi