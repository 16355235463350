import React from 'react'
import { Global, css } from '@emotion/react'
import styled from '@emotion/styled'
import SanityLink from '~components/SanityLink'
import { ArrowCircle, Arrow } from '~components/Svg'

export const breakpoints =  {
	mobile: '@media (max-width: 768px)',
	tablet: '@media (max-width: 1024px)',
	desktop: '@media (max-width: 1230px)',
	desktopUp: '@media (min-width: 1025px)'
}

export const mobile = breakpoints.mobile
export const tablet = breakpoints.tablet
export const desktop = breakpoints.desktop
export const desktopUp = breakpoints.desktopUp


const GlobalStyles = () => (
	<>
		<Global styles={reset} />
		<Global styles={styles} />
	</>
)

const styles = css`
  body {
    font-family: 'ABCDiatypeMedium', sans-serif;
		font-weight: 400;
		background-color: var(--lightBeige);
  }

	html,body {
		font-size: 26px;
		line-height: 34px;
		${tablet}{
			font-size: 20px;
			line-height: 26px;
		}
	}

  :root{
    --white: #ffffff;
    --black: #000000;
		--beige: #EEEBE3;
		--lightBeige: #F7F5EF;
		--purple: #8850AA;
		--lightPurple: #B5B5DB;
		--green: #239B75;
		--lightGreen: #7FC9C8;
		--orange: #FA7D3B;
		--lightOrange: #FFC9B6;
		--grey: #788591;
		--blue: #215485;
		--lightBlue: #61BAFA;
		--crimson: #94382E;
		--yellow: #F2A63D;
		--pink: #FFC9B5;
		--offWhite: #F7F7F0;
    --xxs: 5px;
    --xs: 10px;
    --s: 20px;
    --m: 30px;
    --l: 40px;
    --xl: 50px;
    --xxl: 80px;
    --xxxl: 100px;
    --xxxxl: 150px;
  }

  ${tablet}{
    :root{
      --xxs: 3px;
      --xs: 7px;
      --s: 15px;
      --m: 20px;
      --l: 30px;
      --xl: 35px;
      --xxl: 40px;
      --xxxl: 60px;
      --xxxxl: 120px;
    }
  }

	.ReactCollapse--collapse {
		transition: height 0.6s cubic-bezier(0.5, 1, 0.89, 1);
	}

  p{
		margin-bottom: 1.2em;
    &:last-child{
      margin-bottom: 0;
    }
  }

	.pullout {
		font-family: 'ABCDiatypeBold', sans-serif;
		font-size: 69px;
		line-height: 67px;
    ${tablet} {
			font-size: 61px;
			line-height: 1;
    }
	}

	.headline {
		font-family: 'ABCDiatypeBold', sans-serif;
		font-weight: 700;
		font-size: 48px;
		line-height: 51px;
    ${tablet} {
			font-size: 28px;
			line-height: 34px;
    }
	}

  h1,
  .h1 {
		font-family: 'ABCDiatypeBold', sans-serif;
		font-weight: 700;
		font-size: 38px;
		line-height: 34px;
    ${tablet} {
			font-size: 28px;
			line-height: 34px;
    }
  }

	h2,
  .h2 {
		font-family: 'ABCDiatypeBold', sans-serif;
		font-weight: 700;
		font-size: 29px;
		line-height: 32px;
    ${tablet} {
			font-size: 20px;
			line-height: 26px;
    }
  }

	.sm{
		font-weight: 400;
		font-size: 21px;
		line-height: 29px;
    ${tablet} {
			font-size: 16px;
			line-height: 22px;
    }
	}

	.accordion{
		font-weight: 400;
		font-size: 18px;
		line-height: 30px;
		${tablet}{
			font-weight: 400;
			font-size: 16px;
			line-height: 22px;
		}
	}
	.link{
		font-weight: 400;
		font-size: 19px;
		line-height: 29px;
    ${tablet} {
			font-family: 'ABCDiatypeBold', sans-serif;
			font-size: 15px;
			line-height: 36px;
			font-weight: 700;
    }
	}

	.link-sm{
		font-weight: 400;
		font-size: 13px;
		line-height: 16px;
		letter-spacing: 0.1em;
		text-transform: uppercase;
    ${desktop} {
			font-family: 'ABCDiatypeBold', sans-serif;
			font-weight: 700;
			text-align: center;
			letter-spacing: 0.1em;
    }
	}

	.caption {
		font-size: 16px;
		line-height: 22px;
	}

	.tag {
		font-size: 12px;
		line-height: 22px;
		letter-spacing: 0.05em;
		text-transform: uppercase;
	}

	.nav {
		font-size: 13px;
		line-height: 16px;
		letter-spacing: 0.1em;
		text-transform: uppercase;
	}
`

export const Tag = styled.div`
	display: inline-block;
	background-color: var(--purple);
	color: var(--white);
	margin-right: 10px;
	padding: 6px 16px 4px;
	margin-bottom: 10px;
`

export const StyledLink = styled(SanityLink)`
	display: inline-flex;
	align-items: center;
	margin-right: 15px;
	transition: color 0.25s;
	text-align: left;
	:hover {
		color: var(--purple);
	}
	${mobile}{
		margin-right: 35px;
	}
`
export const ArrowDown = styled(ArrowCircle)`
  width: 13px;
	margin-left: 3px;
	position: relative;
	min-width: 13px;
	top: -1px;
	${mobile}{
		top: -2px;
	}
`
export const ArrowRight = styled(ArrowDown)`
	transform: rotate(270deg);
`
export const ReadMore = styled(SanityLink)`
	transition: color 0.25s;
	:hover {
		color: var(--purple);
	}
`
export const Caption = styled.div`
	margin-top: 20px;
	${mobile}{
		margin-top: 10px;
	}
`
export const ArrowIcon = styled(Arrow)`
	width: 14px;
	margin-left: 10px;
	${mobile}{
		width: 9px;
		margin-left: 4px;
	}
`
export const ColorButton = styled.button`
	transition: color 0.25s;
	:hover {
		color: var(--purple);
	}
`
const reset = css`

  body {
    margin: 0px;
    min-height: 100%;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: inherit;
    font-size: inherit;
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
  }

  ul, ol, blockquote {
    margin: 0;
    padding: 0;
  }

  li {
    margin: 0.5em 0;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
		/*  This prevents chrome from annoyingly adjusting the scroll to try and compensate for moving elements */
		overflow-anchor: none;
  }

  button{
    -webkit-appearance: none;
    border: none;
    background: none;
    font-size: inherit;
    font-family: inherit;
    cursor: pointer;
    padding: 0;
    margin: 0;
  }

  input:-webkit-autofill,
	input:-webkit-autofill:hover, 
	input:-webkit-autofill:focus,
	textarea:-webkit-autofill,
	textarea:-webkit-autofill:hover,
	textarea:-webkit-autofill:focus,
	select:-webkit-autofill,
	select:-webkit-autofill:hover,
	select:-webkit-autofill:focus {
		border: none;
		-webkit-text-fill-color: inherit;
		-webkit-box-shadow: 0 0 0px 0px transparent inset;
		transition: background-color 5000000s ease-in-out 0s;
	}

	/* Prevent safari blue links */
	button, option {
    color: inherit;
  }
`

export default GlobalStyles
