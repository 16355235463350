import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import { useCallback } from 'react'
import Player from '@vimeo/player'
import Image from '~components/Image'
import { Play } from '~components/Svg'

const VimeoPlayer = ({ className, video, image }) => {

	const [player, setPlayer] = useState()
	const [play, setPlay] = useState(false)

	const videoContainer = useCallback(node => {
		let options = {
			id: video,
			loop: true,
			byline: false,
			title: false,
			portrait: false,
			controls: true,
			muted: true
		}
		if(node) setPlayer(new Player(node, options))
	}, [])

	useEffect(() => {
		// setTimeout(() => {
		// 	playVideo()
		// }, 0)
	}, [player])

	const playVideo = () => {
		setPlay(true)
		player?.play()
	}

	console.log({image})

	return (
		<Wrap className={className}>

			{!play && 
				<PlayOverlay play={play} onClick={() => playVideo()}><PlayButton><Play/></PlayButton></PlayOverlay>
			}
			<PlaceholderImage play={play} image={image} cover/>
			<Video ref={videoContainer}/>
		</Wrap>
	)
}

const Wrap = styled.div`
	position: relative;
	margin-bottom: 20px;
	${mobile}{
		margin-bottom: 10px;
	}
`
const Video = styled.div`
	height: 0;
	padding-bottom: 56.25%;
	position: relative;
	iframe{
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		width: 100%;
    height: 100%;
	}
`
const PlayOverlay = styled.button`
	pointer-events: ${ props => props.play ? 'none' : 'all'};
	opacity: ${ props => props.play ? '0' : '1'};
	transition: opacity 0.2s;
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 3;
`
const PlayButton = styled.button`
	width: 78px;
	color: var(--lightOrange);
	${mobile}{
		width: 42px;
	}
`
const PlaceholderImage = styled(Image)`
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 2;
	pointer-events: ${ props => props.play ? 'none' : 'all'};
	opacity: ${ props => props.play ? '0' : '1'};
	transition: opacity 0.2s;
`

VimeoPlayer.propTypes = {
	className: PropTypes.string,
	videoId: PropTypes.string,
	video: PropTypes.string,
	placeholderImage: PropTypes.object,
}

export default VimeoPlayer