import { useState, useEffect } from 'react'

const useStaticWindowHeight = () => {
	const [currentHeight, setCurrentHeight] = useState()

	useEffect(() => {
		setCurrentHeight(window.innerHeight)
	}, [])

	return currentHeight
}

export default useStaticWindowHeight