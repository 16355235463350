import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import sendToMailchimp from '../utils/sendToMailchimp'
import { mobile } from '~styles/global'

const NewsletterSignupForm = ({ className, successMessage, showName }) => {
	const [values, setValues] = useState({})
	const [errors, setErrors] = useState([])
	const [success, setSuccess] = useState(false)

	const validate = e => {
		e.preventDefault()
		const newErrors = []

		if(!values.email) newErrors.push({
			field: 'email',
			message: 'The email field is required'
		})

		if(values.email && (!values.email.includes('@') || !values.email.includes('.'))) newErrors.push({
			field: 'email',
			message: 'Please check your email address'
		})

		if(newErrors.length === 0){
			submit()
		}
		else{
			setErrors(newErrors)
		}
	}

	const submit = () => {
		sendToMailchimp(values.email, values.fname, values.lname, (err, data) => {
			if(err){
				console.log(err, data)
				setErrors([{
					field: null,
					message: 'Sorry we can\'t sign you up right now.'
				}])
			}
			else{
				setSuccess(true)
			}
		})
	}

	const errorFields = errors.map(error => error.field)

	return (
		<Wrap className={className}>
			<Success 
				className='caption'
				css={css`
					opacity: ${success ? '1' : '0'};
					pointer-events: ${success ? 'all' : 'none'};
				`}
			>
				{successMessage}
			</Success>
			<FormWrap css={css`
        opacity: ${success ? '0' : '1'};
        pointer-events: ${success ? 'none' : 'all'};
        `}>
				<Form onSubmit={e => validate(e)} noValidate>
					{showName &&
					<>
						<input
							type="text"
							name="fname"
							placeholder="First Name"
							onChange={e => setValues({...values, fname: e.target.value})}
						/>
						<input
							type="text"
							name="lname"
							placeholder="Last Name"
							onChange={e => setValues({...values, lname: e.target.value})}
						/>
					</>
					}
					<Input
						type="email"
						name="email"
						placeholder="Email"
						className='caption'
						css={css`
            border-color: ${errorFields.includes('email') ? 'var(--crimson)' : 'black'} ;
          `}
						onChange={e => setValues({...values, email: e.target.value})}
					/>
					{errors?.length > 0 &&
					<Errors className='caption'>
						{errors.map(error => <p>{error.message}</p>)}
					</Errors>
					}
					<Submit type="submit" className='caption'>
            Submit
					</Submit>
				</Form>
			</FormWrap>
		</Wrap>
	)
}

const Wrap = styled.div`
  position: relative;
`

const FormWrap = styled.div`
  position: relative;
  transition: opacity 0.3s;
`

const Errors = styled.div`
	top: calc(100% + 12px);
	position: absolute;
	color: var(--crimson);
`

const Success = styled.h5`
  position: absolute;
  top: 0;
  left: 0;
	bottom: 0;
	right: 0;
  transition: opacity 0.3s;
	display: flex;
	align-items: center;
`
const Form = styled.form`
	display: flex;
	${mobile}{
		display: block;
	}
`
const Input = styled.input`
	height: 58px;
	flex: 1;
	border: none;
	padding: 0 30px;
	font-family: inherit;
	${mobile}{
		height: 50px;
		width: 100%;
		box-sizing: border-box;
	}
`
const Submit = styled.button`
	min-width: 208px;
	margin-left: 20px;
	background-color: var(--purple);
	color: var(--white);
	:hover{
		background-color: var(--lightPurple);
	}
	${mobile}{
		height: 50px;
		margin-top: 13px;
		margin-left: 0;
		min-width: 150px;
	}
`

NewsletterSignupForm.propTypes = {
	successMessage: PropTypes.string,
	showName: PropTypes.bool,
	className: PropTypes.string,
}

export default NewsletterSignupForm
