import React from 'react'
import PropTypes from 'prop-types'
import resolveLink from '~utils/resolveLink'
import { Link } from 'gatsby'
import BlockContent from '@sanity/block-content-to-react'
import styled from '@emotion/styled'
import { tablet, Caption, StyledLink, ArrowDown, ArrowRight } from '~styles/global'
import Image from '~components/Image'
import Multi from '~components/Multi'
import VimeoPlayer from '~components/VimeoPlayer'

const serializers = {
	marks: {
		internalLink: props => {
			return <Link to={resolveLink(props.mark.page)}>{props.children}</Link> 
		},
		link: props => {
			if(props.mark.linkType === 'internal'){
				return <Link to={resolveLink(props.mark.document)}>{props.children}</Link> 
			} else {
				return <a href={props.mark?.url} target={props.mark.blank ? '_blank' : '_self'} rel='noreferrer'>{props.children}</a>
			}
		}
	},
	types: {
		imageWithCaption: props => (
			<>
				<Image image={props.node}/>
				<Caption className='caption'>
					<Multi>{props.node?.caption}</Multi>
				</Caption>
			</>
		),
		button: props => (
			<StyledLink className='link-sm' link={props.node?.link}>
				<Multi>{props.node?.text}</Multi>
				{props.node?.link?.linkType === 'file' ? 
					<ArrowDown />
					:
					<ArrowRight />
				}
			</StyledLink>
		),
		vimeoPlayer: props => (
			<VimeoPlayer video={props?.node?.video} image={props?.node?.placeholderImage}/>
		)
	}
}

const RichText = ({ content, textSyles }) => (
	textSyles ? 
		<TextStyles>
			<BlockContent blocks={content} serializers={serializers} ignoreUnknownTypes={true}/>
		</TextStyles>
		: 
		<BlockContent blocks={content} serializers={serializers} ignoreUnknownTypes={true}/>
)

const TextStyles = styled.div`
	h2, h4 {
		margin-bottom: 1.4rem;
	}
	p {
		font-size: 26px;
    line-height: 36px;
		font-weight: 400;
    ${tablet} {
			font-size: 15px;
			line-height: 21px;
    }
	}
	ul {
		${tablet} {
			font-size: 15px;
			line-height: 21px;
    }
	}
	.caption > p{
		font-size: inherit;
	}
`


RichText.propTypes = {
	content: PropTypes.array,
	textSyles: PropTypes.bool,
}

export default RichText
